import { richTextToHtmlString } from '../../../utils/richTextToHtmlString';
import { AccordionRow } from "@sprinklr/shared-lib/@types/entities";
import { FaqQuestion, FaqSchema } from './types';

function generateFaqQuestion(name: string, text: string): FaqQuestion {
    return {
        '@type': 'Question',
        name,
        acceptedAnswer: {
            '@type': 'Answer',
            text,
        },
    };
};

export const getFaqSchema = (rows: AccordionRow[]): FaqSchema | undefined => {
    const mainEntity = rows.filter(({ isIndexable }) => isIndexable).map(({ title, content }) => generateFaqQuestion(title, richTextToHtmlString(content)));
    if (!mainEntity.length) return undefined;
    return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity
    }
}