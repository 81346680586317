/** @jsx jsx */
import React from 'react';
import { Box, Grid, jsx, Themed } from 'theme-ui';
import CompanySize from '@sprinklr/shared-lib/assets/svgs/company_size.svg';
import Location from '@sprinklr/shared-lib/assets/svgs/location.svg';
import Industry from '@sprinklr/shared-lib/assets/svgs/industry.svg';
import SocialIcon from '@sprinklr/shared-lib/assets/svgs/social.svg';
import ServiceIcon from '@sprinklr/shared-lib/assets/svgs/sprinklr-service.svg';
import MarketingIcon from '@sprinklr/shared-lib/assets/svgs/sprinklr-marketing.svg';
import InsightsIcon from '@sprinklr/shared-lib/assets/svgs/insights.svg';
import { useTranslation } from 'react-i18next';
import { CustomerInfoSectionProps, InfoGroupProps } from '../types';

const Icon = ({ name, iconSx = {} }) => {
  const iconList = {
    CompanySize: CompanySize,
    Location: Location,
    Industry: Industry,
    'Sprinklr Marketing': MarketingIcon,
    'Sprinklr Insights': InsightsIcon,
    'Sprinklr Service': ServiceIcon,
    'Sprinklr Social': SocialIcon,
  };

  const IconElement = iconList[name];

  return (
    <Box
      sx={{
        width: ['20px', '30px'],
        position: 'relative',
        top: ['2px', 0],
        svg: { width: ['20px', '30px'], height: ['20px', '30px'] },
      }}
    >
      <IconElement story={name} sx={iconSx} />
    </Box>
  );
};

const Title = ({ title }) => {
  return (
    <Themed.h4
      sx={{
        color: '#979797',
        textTransform: 'uppercase',
        fontSize: [1, 2, 2],
        lineHeight: ['1.5', '1.5'],
        mb: [2],
      }}
    >
      {title}:
    </Themed.h4>
  );
};

const InfoGroup: React.FC<InfoGroupProps> = ({
  title,
  content,
  IconName,
  customerUrl,
}) => {
  return (
    <Box
      sx={{
        mb: [4, 4, 4],
      }}
    >
      <Title title={title} />
      <Grid
        sx={{
          gridTemplateColumns: IconName ? ['20px 1fr', '30px 1fr'] : '1fr',
          gridGap: ['12px'],
        }}
      >
        {IconName && <Icon name={IconName} />}
        <Box>
          {customerUrl ? (
            <Themed.a
              href={customerUrl}
              sx={{ m: 0, width: 'auto', fontSize: [2, 3, 4] }}
              target="_blank"
            >
              {content}
            </Themed.a>
          ) : (
            <Themed.p sx={{ m: 0 }}>{content}</Themed.p>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

const CustomerInfoSection: React.FC<CustomerInfoSectionProps> = ({
  customerName,
  customerUrl,
  customerIndustry,
  customerCompanySize,
  customerLocation,
  customerProductSolutions,
}) => {
  const { t } = useTranslation();

  const custInfoStyles = {
    position: 'relative',
    backgroundColor: '#F8F8FA',
    borderRadius: [0, 0, 0, '20px'],
    px: [0, null, null, 4],
    pt: [4, 4],
    pb: [4],
    mb: [3, 4, 4, 3],
    '&::before, &::after': {
      content: '""',
      backgroundColor: '#F8F8FA',
      position: 'absolute',
      width: ['22px', '80px', '80px', 0],
      top: 0,
      bottom: 0,
    },
    '&::before': {
      right: '100%',
    },
    '&::after': {
      left: '100%',
    },
  };

  return (
    <Box sx={{ ...custInfoStyles }}>
      {customerName && (
        <InfoGroup
          title={t('Customer')}
          content={customerName}
          customerUrl={customerUrl}
        />
      )}
      {customerIndustry && (
        <InfoGroup
          title={t('Industry')}
          content={customerIndustry}
          IconName="Industry"
        />
      )}
      {customerCompanySize && (
        <InfoGroup
          title={t('Company Size')}
          content={customerCompanySize}
          IconName="CompanySize"
        />
      )}
      {customerLocation && (
        <InfoGroup
          title={t('Location')}
          content={customerLocation}
          IconName="Location"
        />
      )}
      {customerProductSolutions && (
        <Box sx={{ mb: [0] }}>
          <Title title={t('Products Featured')} />
          {customerProductSolutions?.map((product, i) => {
            const isLast = customerProductSolutions.length - 1 === i;

            let slug;
            if (product === 'Sprinklr Marketing')
              slug = '/products/marketing-and-advertising/';
            if (product === 'Sprinklr Insights')
              slug = '/products/consumer-intelligence/';
            if (product === 'Sprinklr Service')
              slug = '/products/customer-service/';
            if (product === 'Sprinklr Social')
              slug = '/products/social-media-management/';
            return (
              <Grid
                sx={{
                  gridTemplateColumns: ['20px 1fr', '30px 1fr'],
                  gridGap: ['12px'],
                  mb: isLast ? [0] : [2],
                }}
                key={i}
              >
                <Icon name={product} />
                <Box>
                  <Themed.a href={slug} sx={{ m: 0, fontSize: [2, 3, 4] }}>
                    {t(product)}
                  </Themed.a>
                </Box>
              </Grid>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default CustomerInfoSection;
