import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { resolveLocale } from '@sprinklr/shared-lib/utils';
import { Container, PreviousPageNav } from '@sprinklr/shared-lib';
import CustomerStoryHero from './components/CustomerStoryHero';
import CustomerStoryBenchmarking from './components/CustomerStoryBenchmarking';
import CustomerStoryForm from './components/CustomerStoryForm';
import CustomerStoryContentColumns from './components/CustomerStoryContentColumns';
import CustomerStorySocialShare from './components/CustomerStorySocialShare';
import { CustomerStoryProps } from './types';

import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

const CustomerStoryTemplate: React.FC<CustomerStoryProps> = props => {
  const {
    challengeAccordion,
    challengeContent,
    challengeMedia,
    challengeQuote,
    customerCompanySize,
    customerIndustry,
    customerLocation,
    customerName,
    customerUrl,
    customerProductSolutions,
    downloadUrl,
    heroHeadline,
    heroImageShouldNotCover,
    heroMedia,
    heroMediaType,
    heroVideoPreviewImage,
    name,
    outcomeAccordion,
    outcomeContent,
    outcomeMedia,
    outcomeQuote,
    solutionAccordion,
    solutionContent,
    solutionMedia,
    solutionQuote,
    stats,
  } = props;

  const { t, language } = useI18next();
  const locale = resolveLocale(language);

  const heroProps = {
    heroHeadline,
    heroImageShouldNotCover,
    heroMedia,
    heroMediaType,
    heroVideoPreviewImage,
  };
  const socialProps = {
    downloadUrl,
    name,
  };
  const contentColumnsProps = {
    challengeAccordion,
    challengeContent,
    challengeMedia,
    challengeQuote,
    customerCompanySize,
    customerIndustry,
    customerLocation,
    customerName,
    customerUrl,
    customerProductSolutions,
    outcomeAccordion,
    outcomeContent,
    outcomeMedia,
    outcomeQuote,
    solutionAccordion,
    solutionContent,
    solutionMedia,
    solutionQuote,
    ...socialProps,
  };

  const searchParams = isWindowDefined()
    ? new URLSearchParams(window.location.search)
    : undefined;
  const returnTo = searchParams ? searchParams.get('returnTo') : undefined;

  return (
    <>
      <Container containerSx={{ pb: [1, 3, 4, 5] }}>
        <PreviousPageNav
          title={returnTo ? t('Back') : t('Back to All Customer Stories')}
          src={returnTo ?? '/stories'}
        />
        <CustomerStoryHero {...heroProps} />
      </Container>
      {stats && <CustomerStoryBenchmarking stats={stats} />}
      <Container containerSx={{ pt: [4, 5, 5, '80px'] }}>
        <CustomerStoryContentColumns {...contentColumnsProps} />
        <CustomerStorySocialShare
          title={t('Share on:')}
          hideOnDesktop={true}
          customerStorySocialShareSx={{ marginBottom: [5, 5, 5, 6] }}
          {...socialProps}
        />

        <CustomerStoryForm locale={locale} />
      </Container>
    </>
  );
};

export default CustomerStoryTemplate;
