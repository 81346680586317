import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export const richTextToHtmlString = (richTextObj: {
  raw: string;
}): string | undefined => {
  try {
    const json = JSON.parse(richTextObj?.raw);
    return documentToHtmlString(json);
  } catch (error) {
    console.error('Error while converting document to html string', error);
  }
};
