/** @jsx jsx */
import React from 'react';
import { Box, Themed, jsx } from 'theme-ui';
import { ContentSectionProps } from '../types';
import RichText from '../../../../../shared-lib/components/richText';
import AccordionBasicTemplate from '../../accordionBasicTemplate';
import { Media, Blockquote } from '@sprinklr/shared-lib';
import { useThemeUI } from 'theme-ui';

const ContentSection: React.FC<ContentSectionProps> = props => {
  const { title, accordion, content, media, quote, contentSectionSx } = props;

  const { theme } = useThemeUI();
  return (
    <Box sx={{ ...contentSectionSx }}>
      <Themed.h2
        sx={{
          mb: [theme.container.sm, theme.container.sm, theme.container.sm],
        }}
      >
        {title}
      </Themed.h2>
      {/* Negative margin accounts for default margin built into bottom of RichText component */}
      {content && (
        <Box sx={{}}>
          <RichText richTextObject={content} />
        </Box>
      )}
      {accordion && (
        <AccordionBasicTemplate
          accordionRows={accordion}
          openIndex={1}
          startOpen={true}
          accordionDisplayStyle="CLEAN"
          topMargin="NONE"
          bottomMargin="NONE"
          noContainerLeftRightPadding={true}
          accordionBasicTemplateSx={{ marginTop: content ? ['40px'] : 0 }}
        />
      )}
      {media && (
        <Media
          mediaType="IMAGE"
          source={media?.asset}
          mobileImage={media?.mobileAsset}
          mediaSx={{
            borderRadius: 2,
            overflow: 'hidden',
            marginTop: content || accordion ? ['60px', '120px'] : 0,
          }}
        />
      )}
      {quote && (
        <Blockquote
          quote={quote?.quote}
          reviewer={quote?.reviewer}
          variant={'PERSONIMAGE'}
          blockquoteSx={{
            marginTop: content || accordion || media ? ['60px', '120px'] : 0,
          }}
        />
      )}
    </Box>
  );
};

export default ContentSection;
