/** @jsx jsx */
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { jsx } from 'theme-ui';
import CustomerStorySocialShare from './CustomerStorySocialShare';
import { CustomerInfoColumnProps } from '../types';
import CustomerInfoSection from './CustomerInfoSection';

const CustomerInfoColumn: React.FC<CustomerInfoColumnProps> = ({
  customerName,
  customerUrl,
  customerIndustry,
  customerCompanySize,
  customerLocation,
  customerProductSolutions,
  downloadUrl,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CustomerInfoSection
        customerName={customerName}
        customerIndustry={customerIndustry}
        customerCompanySize={customerCompanySize}
        customerLocation={customerLocation}
        customerProductSolutions={customerProductSolutions}
        customerUrl={customerUrl}
      />
      <CustomerStorySocialShare
        title={t('Share on:')}
        name={name}
        downloadUrl={downloadUrl}
      />
    </React.Fragment>
  );
};

export default CustomerInfoColumn;
