import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import FormTemplate from '../../../../src/templates/formTemplate';

type FormProps = {
  description?: Document;
  formType?: string;
  id?: string;
  on24_id?: string;
  on24_key?: string;
  sfCampaign?: string;
  redirectSlug?: string;
  externalRedirectUrl?: string;
  hasBackgroundImage?: boolean;
  isModal?: boolean;
  ctaLabel?: string;
  headline?: string;
  successMessage?: Document;
  isHeroForm?: boolean;
  isFullWidth?: boolean;
  customFormId?: string;
  locale?: string;
};

const CustomerStoryForm: React.FC<FormProps> = props => {
  const { locale } = props;
  //querying `home-form` from Contentful to populate the form

  const data = useStaticQuery(graphql`
    {
      allContentfulTemplateForm(
        filter: { name: { eq: "Demo Request - Hubspot Form" } }
      ) {
        nodes {
          ...ContentfulTemplateFormNode
        }
      }
    }
  `);

  const displayData = data?.allContentfulTemplateForm?.nodes.find(
    form => form?.node_locale.toLowerCase() === locale,
  );

  const {
    formType,
    description,
    ctaLabel,
    successMessage,
    on24_id,
    on24_key,
    redirectSlug,
    sfCampaign,
    headline,
    externalRedirectUrl,
    customFormId,
    ...rest
  } = displayData;

  return (
    <FormTemplate
      description={description}
      formType={formType}
      on24_id={on24_id}
      on24_key={on24_key}
      sfCampaign={sfCampaign}
      isFullWidth={true}
      ctaLabel={ctaLabel}
      successMessage={successMessage}
      redirectSlug={redirectSlug}
      headline={headline}
      externalRedirectUrl={externalRedirectUrl}
      customFormId={customFormId}
      {...rest}
    />
  );
};

export default CustomerStoryForm;
