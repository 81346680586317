import {
  getCorrectedEncodeUriComponent,
  SocialLink,
} from '@sprinklr/shared-lib';
import { Flex, Box } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Tooltip from '@sprinklr/shared-lib/components/tooltip';
import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useLocation } from '@reach/router';
import ReactTooltip from 'react-tooltip';
import { CustomerStorySocialShareProps } from '../types';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

const CustomerStorySocialShare = ({
  name,
  downloadUrl,
  title,
  hideOnDesktop,
  customerStorySocialShareSx,
}: CustomerStorySocialShareProps): ReactElement => {
  const uriEncodedName = getCorrectedEncodeUriComponent(name);
  const location = useLocation();
  const { t } = useTranslation();
  const linkCopiedLabel = t('link copied').toUpperCase();
  const [url, setUrl] = useState('');

  const tooltipRef = useRef(null);
  const showToolTip = useCallback(() => {
    ReactTooltip.show(tooltipRef.current);
  }, [tooltipRef]);

  useEffect(() => {
    if (isWindowDefined()) {
      setUrl(location.href);
    }
  }, [location]);

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <Box
      sx={{
        maxWidth: '600px',
        margin: '0 auto',
        ...customerStorySocialShareSx,
      }}
    >
      {title && (
        <Box
          sx={{
            display: ['block', null, null, hideOnDesktop && 'none'],
            fontSize: [4, 6, null, 4],
            fontWeight: 'body',
            pb: [3],
            textAlign: 'center',
          }}
        >
          {t('Share on:')}
        </Box>
      )}
      <Flex
        sx={{
          justifyContent: 'space-between',
          display: ['block', null, null, hideOnDesktop && 'none'],
        }}
      >
        <SocialLink
          key={'facebook'}
          socialLink={{
            channelType: 'FACEBOOK',
            url: `https://www.facebook.com/sharer.php?u=${url}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
          shouldOpenNewWindow={true}
        />
        <SocialLink
          key={'twitter'}
          socialLink={{
            channelType: 'TWITTER',
            url: `https://twitter.com/intent/tweet?text=${uriEncodedName}&url=${url}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
          shouldOpenNewWindow={true}
        />
        <SocialLink
          key={'linkedin'}
          socialLink={{
            channelType: 'LINKEDIN',
            url: `https://www.linkedin.com/shareArticle?url=${url}&title=${uriEncodedName}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
          shouldOpenNewWindow={true}
        />
        <SocialLink
          key={'mail'}
          socialLink={{
            channelType: 'MAIL',
            url: `mailto:?subject=Sprinklr.com: ${name}&body=${url}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
        />
        {downloadUrl && (
          <SocialLink
            key={'download'}
            socialLink={{ channelType: 'DOWNLOAD', url: downloadUrl }}
            variant={'GREY2COLOR'}
            fillColor={'#383F3B'}
          />
        )}
        <SocialLink
          key={'link'}
          socialLink={{ channelType: 'LINK', url: null }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
          dataTip={linkCopiedLabel}
          dataEvent={'focus'}
          clickFunc={showToolTip}
          ref={tooltipRef}
        />
      </Flex>
      <Tooltip />
    </Box>
  );
};

export default CustomerStorySocialShare;
