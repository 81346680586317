import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { resolveLocale } from '@sprinklr/shared-lib/utils';
import { CustomerStoryHeroProps } from '../types';
import HeroTemplate from '../../heroTemplate';

const CustomerStoryHero: React.FC<CustomerStoryHeroProps> = ({
  heroHeadline,
  heroImageShouldNotCover,
  heroMedia,
  heroMediaType,
  heroVideoPreviewImage,
}) => {
  const { t, language } = useI18next();
  const nodeLocale = resolveLocale(language, true, 'en');

  const data = useStaticQuery(graphql`
    {
      requestDemoForm: allContentfulTemplateForm(
        filter: { uniqueFormUseCaseId: { eq: "STORIES_REQUEST_DEMO" } }
      ) {
        nodes {
          ...ContentfulTemplateFormNode
        }
      }
    }
  `);

  const formData = data?.requestDemoForm?.nodes?.find(
    form => form.node_locale === nodeLocale,
  );

  const ctaProps = {
    action: 'OPEN_MODAL',
    genModalContent: formData,
    text: t('request demo'),
    type: 'BUTTON',
    variant: 'primary',
  };

  return (
    <HeroTemplate
      heroHeadline={heroHeadline}
      topMargin="SMALL"
      bottomMargin="SMALL"
      image={heroMedia?.asset}
      mobileImage={heroMedia?.mobileAsset}
      previewImage={heroVideoPreviewImage}
      contentSplit="1/2"
      textPosition="LEFT"
      noContainerLeftRightPadding={true}
      imageShouldNotCover={heroImageShouldNotCover}
      mediaType={heroMediaType}
      verticalAlignment="TOP"
      reverseStackingOrder={true}
      ctas={[ctaProps]}
      externalVideoUrl={heroMedia?.externalVideoUrl}
    />
  );
};

export default CustomerStoryHero;
