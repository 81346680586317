/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ContentSection from './ContentSection';
import {
  ChallengeSectionProps,
  OutcomeSectionProps,
  SolutionSectionProps,
} from '../types';

const ContentColumn: React.FC<
  ChallengeSectionProps & OutcomeSectionProps & SolutionSectionProps
> = props => {
  const {
    challengeAccordion,
    challengeContent,
    challengeMedia,
    challengeQuote,
    outcomeAccordion,
    outcomeContent,
    outcomeMedia,
    outcomeQuote,
    solutionAccordion,
    solutionContent,
    solutionMedia,
    solutionQuote,
  } = props;

  const { t } = useTranslation();

  let hasChallenge;
  if (
    challengeAccordion ||
    challengeContent ||
    challengeMedia ||
    challengeQuote
  )
    hasChallenge = true;

  let hasSolution;
  if (solutionAccordion || solutionContent || solutionMedia || solutionQuote)
    hasSolution = true;

  let hasOutcome;
  if (outcomeAccordion || outcomeContent || outcomeMedia || outcomeQuote)
    hasOutcome = true;

  return (
    <React.Fragment>
      {hasChallenge && (
        <ContentSection
          title={t('Challenge')}
          accordion={challengeAccordion}
          content={challengeContent}
          media={challengeMedia}
          quote={challengeQuote}
        />
      )}
      {hasSolution && (
        <ContentSection
          title={t('Solution')}
          accordion={solutionAccordion}
          content={solutionContent}
          media={solutionMedia}
          quote={solutionQuote}
          contentSectionSx={{ pt: hasChallenge ? [5, 6, 6, 6] : 0 }}
        />
      )}
      {hasOutcome && (
        <ContentSection
          title={t('Outcome')}
          accordion={outcomeAccordion}
          content={outcomeContent}
          media={outcomeMedia}
          quote={outcomeQuote}
          contentSectionSx={{
            pt: hasChallenge || hasSolution ? [5, 6, 6, 6] : 0,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ContentColumn;
