/** @jsx jsx */
import { Grid, Box, jsx } from 'theme-ui';
import { CustomerStoryContentColumnsProps } from '../types';
import ContentColumn from './ContentColumn';
import CustomerInfoColumn from './CustomerInfoColumn';
import StickyElement from '@sprinklr/shared-lib/components/stickyElement';

const CustomerStoryContentColumns: React.FC<
  CustomerStoryContentColumnsProps
> = props => {
  const {
    challengeAccordion,
    challengeContent,
    challengeMedia,
    challengeQuote,
    customerCompanySize,
    customerIndustry,
    customerLocation,
    customerName,
    customerUrl,
    customerProductSolutions,
    outcomeAccordion,
    outcomeContent,
    outcomeMedia,
    outcomeQuote,
    solutionAccordion,
    solutionContent,
    solutionMedia,
    solutionQuote,
    downloadUrl,
    name,
  } = props;
  const contentColProps = {
    challengeAccordion,
    challengeContent,
    challengeMedia,
    challengeQuote,
    outcomeAccordion,
    outcomeContent,
    outcomeMedia,
    outcomeQuote,
    solutionAccordion,
    solutionContent,
    solutionMedia,
    solutionQuote,
  };

  const custInfoColProps = {
    customerCompanySize,
    customerIndustry,
    customerLocation,
    customerName,
    customerUrl,
    customerProductSolutions,
    downloadUrl,
    name,
  };

  return (
    <Grid
      sx={{
        gridTemplateColumns: ['1fr', '1fr', '1fr', '2fr 1fr'],
        gridGap: [5, 5],
        alignItems: 'column-reverse',
        marginBottom: [5, 5, 5, 6],
      }}
    >
      <Box sx={{ order: ['2', null, null, '1'] }}>
        <ContentColumn {...contentColProps} />
      </Box>
      <Box sx={{ order: ['1', null, null, '2'] }}>
        <StickyElement stickyElementSx={{ top: ['145px'] }}>
          <CustomerInfoColumn {...custInfoColProps} />
        </StickyElement>
      </Box>
    </Grid>
  );
};

export default CustomerStoryContentColumns;
