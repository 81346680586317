import React from 'react';
import { Flex, Box, ThemeProvider } from 'theme-ui';
import { Card, Container } from '@sprinklr/shared-lib';
import { RichText } from '@sprinklr/shared-lib';

type StatProps = {
  stats: Array<Record<string, unknown>>;
};

const CustomerStoryBenchmarking: React.FC<StatProps> = props => {
  const { stats } = props;

  return (
    <Box sx={{ backgroundColor: '#F8F8FA' }}>
      <Container containerSx={{ py: 0 }}>
        <Flex
          sx={{
            flexWrap: 'wrap',
            width: ['calc(100% + 32px)', 'calc(100% + 64px)'],
            justifyContent: stats.length > 2 ? 'space-between' : 'space-around',
            mx: ['-16px', -4],
            py: [4, 0],
          }}
        >
          {stats.map((stat, i) => {
            return (
              <Card
                key={i}
                cardSx={{
                  minWidth: '100px',
                  maxWidth: '514px',
                  px: [3],
                  py: [1, 4],
                  justifyContent: 'flex-start',
                  // When only 3 stats, display them 3 across at tablet width.  When 4 stack them.
                  width: [
                    '100%',
                    stats.length % 2 ? '33%' : '50%',
                    null,
                    stats.length % 2 ? '33%' : '25%',
                  ],
                  backgroundColor: 'F8F8FA',
                  borderRadius: '0px',
                  boxShadow: 'none',
                }}
              >
                <ThemeProvider
                  theme={{
                    styles: {
                      h2: {
                        fontSize: [null, '32px'],
                        lineHeight: [null, '40px'],
                        mb: [1, 2],
                      },
                      p: { mb: 0 },
                    },
                  }}
                >
                  <RichText richTextObject={stat.text} />
                </ThemeProvider>
              </Card>
            );
          })}
        </Flex>
      </Container>
    </Box>
  );
};

export default CustomerStoryBenchmarking;
