import React, { useMemo } from 'react';
import Accordion from '@sprinklr/shared-lib/components/accordion';
import Container from '@sprinklr/shared-lib/components/container';
import { AccordionBasicTemplateProps } from './types';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { assignDefaultProp } from '@sprinklr/shared-lib/utils/assignDefaultProp';

import { SIZE_VARIANTS } from './sizeVariants';
import { HeadSchemaAdditions } from '@sprinklr/shared-lib';
import { getFaqSchema } from './getFaqSchema';

const getMaxWidth = sizeVariant => {
  if (!sizeVariant) {
    return undefined;
  }
  if (sizeVariant === SIZE_VARIANTS.mw1180) {
    return 1180;
  }
  return undefined;
};

const AccordionBasicTemplate = (props: AccordionBasicTemplateProps) => {
  const {
    sectionId,
    noContainerLeftRightPadding,
    noContainerTopBottomPadding,
    topMargin,
    bottomMargin,
    rowShouldDisplayImage,
    accordionBasicTemplateSx,
    sizeVariant,
    accordionRows,
  } = props;

  const openIndex = assignDefaultProp(props.openIndex, 0);
  const startOpen = assignDefaultProp(props.startOpen, true);
  const accordionDisplayStyle = assignDefaultProp(
    props.accordionDisplayStyle,
    'SHADOW',
  );

  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);
  const containerSx = {
    pt: pt,
    pb: pb,
    ...accordionBasicTemplateSx,
  };
  const maxWidth = getMaxWidth(sizeVariant);

  const validatedOpenIndex = openIndex > 0 ? openIndex - 1 : 0;

  const modifiedAccordion = useMemo(
    () =>
      props.accordionRows?.map(({ content, ...rest }) => ({
        ...rest,
        content: content,
      })),
    [props.accordionRows],
  );

  const content = useMemo(() => {
    return modifiedAccordion?.map(({ title, content, rowImage }, index) => ({
      title,
      content,
      rowImage,
      index,
    }));
  }, [modifiedAccordion]);

  const faqSchema = useMemo(() => getFaqSchema(accordionRows), [accordionRows]);

  return (
    <Container
      id={sectionId}
      fullWidth={noContainerLeftRightPadding}
      fullHeight={noContainerTopBottomPadding}
      containerSx={containerSx}
      maxWidth={maxWidth}
    >
      {faqSchema && <HeadSchemaAdditions schema={faqSchema} />}
      <Accordion
        content={content}
        openIndex={startOpen ? validatedOpenIndex : null}
        variant="SINGLE"
        accordionDisplayStyle={accordionDisplayStyle}
        rowCount={modifiedAccordion?.length}
        rowShouldDisplayImage={rowShouldDisplayImage}
      />
    </Container>
  );
};

export default AccordionBasicTemplate;
